import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import ResponsiveTable from "material-ui-next-responsive-table";
import { MixpanelConsumer } from "react-mixpanel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";

import AddStock from "./AddStock.js";
import AuthUserContext from "./AuthUserContext";
import withAuthorization from "./withAuthorization";
import Emoji from "./Emoji.js";
import Loading from "./Loading.js";
import StrategySelect from "./StrategySelect.js";

import * as utils from "../utils";
import { db } from "../firebase";

const columns = [
  {
    key: "stock",
    // label: "Stock",
    label: {
      toUpperCase: () => {
        return (
          <>
            <div
              data-tip="Symbol for the stock or a pair of currencies"
              data-for="symbol"
            >
              STOCK
            </div>
            <ReactTooltip id="symbol" />
          </>
        );
      }
    },
    primary: true,
    render: value => <strong>{value}</strong>
  },
  {
    key: "sma50",
    // label: "SMA 50"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Simple Moving Avarage 50 days" data-for="sma50">
              SMA 50
            </div>
            <ReactTooltip id="sma50" />
          </>
        );
      }
    }
  },
  {
    key: "sma200",
    // label: "SMA 200"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Simple Moving Avarage 200 days" data-for="sma200">
              SMA 200
            </div>
            <ReactTooltip id="sma200" />
          </>
        );
      }
    }
  },
  {
    key: "trend",
    // label: "Trend",
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Trend according to the strategy" data-for="trend">
              TREND
            </div>
            <ReactTooltip id="trend" />
          </>
        );
      }
    },
    primary: true,
    render: value => <strong>{value}</strong>
  },
  {
    key: "trailing",
    // label: "Trailing days"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Amount of days with this trend" data-for="trailing">
              TRAILING DAYS
            </div>
            <ReactTooltip id="trailing" />
          </>
        );
      }
    }
  },
  {
    key: "closing",
    // label: "Closing Day"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div
              data-tip="Values are closing price at this date"
              data-for="closing"
            >
              DATE
            </div>
            <ReactTooltip id="closing" />
          </>
        );
      }
    }
  },
  {
    key: "status",
    // label: "Updated"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div
              data-tip="Last time Chekr updated this symbol"
              data-for="status"
            >
              UPDATED
            </div>
            <ReactTooltip id="status" />
          </>
        );
      }
    }
  },
  {
    key: "owned",
    //label: "Owned"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Do you currently own this stock?" data-for="owned">
              OWNED
            </div>
            <ReactTooltip id="owned" />
          </>
        );
      }
    }
  },
  {
    key: "action",
    label: "Action"
  }
];

class Stocks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stocks: {},
      allStocks: {},
      user: null,
      loading: true,
      sortColumn: "TREND",
      sortOrder: null,
      filter: null
    };

    this.stockAdded = this.stockAdded.bind(this);
    this.stockRemoved = this.stockRemoved.bind(this);
    this.stockUpdated = this.stockUpdated.bind(this);
    this.doRemoveStock = this.doRemoveStock.bind(this);
    this.doUpdateStockOwn = this.doUpdateStockOwn.bind(this);
    this.mapStocksToGrid = this.mapStocksToGrid.bind(this);
    this.sortStock = this.sortStock.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  stockAdded(key, object) {
    let updatedStocks = this.state.stocks;
    updatedStocks[key] = object;
    Object.values(this.state.allStocks)
      .filter(stock => stock.symbol === object.symbol)
      .map(stock => {
        // PS this makes the addedDate come from the stock object
        Object.assign(updatedStocks[key], stock);
        return updatedStocks[key];
      });
    this.setState({ stocks: updatedStocks });
  }

  stockRemoved(key) {
    let updatedStocks = this.state.stocks;
    delete updatedStocks[key];
    this.setState({ stocks: updatedStocks });
  }

  stockUpdated(key, object) {
    let updatedStocks = this.state.stocks;

    // workaround due to old stock data in user object
    let newObject = { owned: object.owned };

    Object.assign(updatedStocks[key], newObject);
    this.setState({ stocks: updatedStocks });
  }

  doRemoveStock(key) {
    db.doRemoveStock(this.props.authUser.uid, key).then(() => {
      this.props.mixpanel.track("Stock Removed");
      this.props.mixpanel.people.increment({
        "Number of Stocks": -1
      });
    });
  }

  doUpdateStockOwn = event => {
    db.doUpdateStock(this.props.authUser.uid, event.target.value, {
      owned: event.target.checked
    });
  };

  handleSortChange(event) {
    this.setState({ sortColumn: event.target.value });
  }

  handleFilterChange(event) {
    this.setState({ filter: event.target.checked ? event.target.value : null });
  }

  mapStocksToGrid = stockEntry => {
    return {
      stock: stockEntry[1].name
        ? stockEntry[1].name +
          " (" +
          stockEntry[1].symbol +
          ") " +
          utils.getFlagFromStock(stockEntry[1])
        : stockEntry[1].symbol + " " + utils.getFlagFromStock(stockEntry[1]) ||
          "",
      sma50: stockEntry[1].sma50 || "",
      sma200: stockEntry[1].sma200 || "",
      trend: stockEntry[1].trend ? (
        <>
          <div
            style={{ display: "inline" }}
            data-tip={
              stockEntry[1].warning === "GOLDEN"
                ? stockEntry[1].symbol + " has reached the Golden Cross 🚀"
                : stockEntry[1].warning === "GOLDEN_WARNING"
                ? stockEntry[1].symbol +
                  " might reach the Golden Cross today if it closes high enough 🚀"
                : stockEntry[1].warning === "DEATH"
                ? stockEntry[1].symbol + " has reached the Death Cross 💀"
                : stockEntry[1].warning === "DEATH_WARNING"
                ? stockEntry[1].symbol +
                  " might reach the Death Cross today if it closes low enough 💀"
                : ""
            }
            data-for={"trend-" + stockEntry[1].symbol}
          >
            {stockEntry[1].trend === "up"
              ? "📈"
              : stockEntry[1].trend === "down"
              ? "📉"
              : ""}
            {stockEntry[1].warning === "DEATH" ||
            stockEntry[1].warning === "DEATH_WARNING"
              ? " 💀"
              : stockEntry[1].warning === "GOLDEN" ||
                stockEntry[1].warning === "GOLDEN_WARNING"
              ? " 🚀"
              : ""}
          </div>
          <ReactTooltip id={"trend-" + stockEntry[1].symbol} />
        </>
      ) : (
        ""
      ),
      trailing: stockEntry[1].trailingDays ? (
        <>
          <div
            data-tip={
              "Since " +
              (stockEntry[1].trailingDays > 199
                ? "eternity? 🤷‍"
                : stockEntry[1].trailingSince)
            }
            data-for={"since-" + stockEntry[1].symbol}
          >
            {stockEntry[1].trailingDays > 199
              ? "200+"
              : stockEntry[1].trailingDays}
          </div>
          <ReactTooltip id={"since-" + stockEntry[1].symbol} />
        </>
      ) : (
        ""
      ),
      closing: stockEntry[1].closingDate || "",
      status:
        stockEntry[1].error === "INCORRECT_SYMBOL" ? (
          <>
            <div
              data-tip={
                "Incorrect symbol<br />" +
                (stockEntry[1].symbol.includes(".")
                  ? "Note: the Swedish markets Spotlight Stock Market and Nordic MTF are currently not supported."
                  : "Make sure you add the correct suffix for the market, e.g. ABB.ST")
              }
              data-for="incorrect_symbol"
            >
              <Emoji symbol="❌" label="Error" />
            </div>
            <ReactTooltip multiline id="incorrect_symbol" />
          </>
        ) : stockEntry[1].error ? (
          <>
            <div data-tip="Something went wrong" data-for="error_unknown">
              <Emoji symbol="🤯" label="Error" />
            </div>
            <ReactTooltip id="error_unknown" />
          </>
        ) : stockEntry[1].runDate ? (
          <>
            <div
              data-tip={
                "Last time indexed: " +
                timeFromRun(stockEntry[1].runDate) +
                "<br /> " +
                "Scheduled next time to be indexed: " +
                timeToRun()
              }
              data-for="lastStatus"
            >
              {timeFromRun(stockEntry[1].runDate)}
            </div>
            <ReactTooltip id="lastStatus" multiline />
          </>
        ) : (
          <>
            <div
              data-tip={"Scheduled next time to be indexed: " + timeToRun()}
              data-for="nextStatus"
            >
              Pending
            </div>
            <ReactTooltip id="nextStatus" />
          </>
        ),
      owned: (
        <Checkbox
          key={"checkbox-" + stockEntry[0]}
          checked={stockEntry[1].owned}
          onChange={event => this.doUpdateStockOwn(event)}
          value={stockEntry[0]}
        />
      ),
      action: (
        <Tooltip title="Remove">
          <IconButton
            aria-label="Delete"
            size="small"
            onClick={() => this.doRemoveStock(stockEntry[0])}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )
    };
  };

  sortStock = (a, b, sortColumn) => {
    if (sortColumn === "STOCK") return a.symbol.localeCompare(b.symbol);
    if (sortColumn === "SMA50")
      return a.error
        ? 1
        : b.error
        ? -1
        : !a.sma50 && !b.sma50
        ? 0
        : !a.sma50
        ? 1
        : !b.sma50
        ? -1
        : b.sma50 - a.sma50;
    if (sortColumn === "SMA200")
      return a.error
        ? 1
        : b.error
        ? -1
        : !a.sma200 && !b.sma200
        ? 0
        : !a.sma200
        ? 1
        : !b.sma200
        ? -1
        : b.sma200 - a.sma200;
    if (sortColumn === "TREND") {
      return a.error
        ? 1
        : b.error
        ? -1
        : !a.trend && !b.trend
        ? 0
        : !a.trend
        ? 1
        : !b.trend
        ? -1
        : a.trend === b.trend && a.trend === "up"
        ? b.trailingDays - a.trailingDays
        : a.trend === b.trend && a.trend === "down"
        ? a.trailingDays - b.trailingDays
        : b.trend.localeCompare(a.trend);
    }
    if (sortColumn === "TRAILING")
      return a.error
        ? 1
        : b.error
        ? -1
        : !a.trailingDays && b.trailingDays
        ? 0
        : !a.trailingDays
        ? 1
        : !b.trailingDays
        ? -1
        : b.trailingDays - a.trailingDays;
    if (sortColumn === "DATE")
      return a.error
        ? 1
        : b.error
        ? -1
        : !a.closingDate && !b.closingDate
        ? 0
        : !a.closingDate
        ? 1
        : !b.closingDate
        ? -1
        : new Date(b.closingDate) - new Date(a.closingDate);
    if (sortColumn === "UPDATED")
      return a.error
        ? 1
        : b.error
        ? -1
        : !a.runDate && !b.runDate
        ? 0
        : !a.runDate
        ? 1
        : !b.runDate
        ? -1
        : b.runDate - a.runDate;
    else return a.symbol.localeCompare(b.symbol);
  };

  componentDidMount() {
    db.onceGetUser(this.props.authUser.uid).then(data => {
      let user = data.val();
      this.setState({ user: user });
      this.props.mixpanel.people.set({
        $name: user.username,
        "Number of Stocks": user.stocks ? Object.values(user.stocks).length : 0
      });
      db.onceGetAllStocks().then(data => {
        this.setState({
          allStocks: data.val(),
          loading: user.stocks && Object.keys(user.stocks).length > 0
        });
        db.listenOnNewStocks(this.props.authUser.uid, data => {
          if (this.state.loading) this.setState({ loading: false });
          this.stockAdded(data.key, data.val());
        });
        db.listenOnRemovedStocks(this.props.authUser.uid, data => {
          this.stockRemoved(data.key);
        });
        db.listenOnUpdatedStocks(this.props.authUser.uid, data => {
          this.stockUpdated(data.key, data.val());
        });
      });
    });
  }

  render() {
    const { user, stocks, allStocks, loading, sortColumn } = this.state;

    return (
      <>
        <h2>My Stocks</h2>
        <p
          style={{
            background: "pink",
            padding: "1rem",
            borderRadius: "0.5rem",
            textAlign: "center"
          }}
        >
          My stock quote provider are having issues for non-US stocks. I'm
          bugging them about it <Emoji symbol="🐜" /> /Niklas
        </p>

        {loading ? (
          <Loading />
        ) : Object.keys(allStocks).length > 0 ? (
          <>
            <div style={{ marginBottom: "2rem" }}>
              <p>
                <Emoji symbol="🔍" /> This is the stocks you watch at the
                moment.
              </p>
              <p>
                <Emoji symbol="✉️" /> When your strategy gives a buy or sell
                signal for one of your stocks, the bot will send you an email.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "0.5rem"
              }}
            >
              <StrategySelect />
              <FormControl
                style={{
                  display: "inline",
                  marginBottom: "1rem",
                  marginRight: "1rem"
                }}
              >
                <InputLabel shrink htmlFor="sort-label">
                  Sort <Emoji symbol="👇" />
                </InputLabel>
                <Select
                  value={sortColumn}
                  onChange={this.handleSortChange}
                  name="sort-select"
                  inputProps={{
                    name: "sort-input",
                    id: "sort-label"
                  }}
                  displayEmpty
                  style={{
                    width: "8rem"
                  }}
                >
                  <MenuItem value="STOCK">Stock</MenuItem>
                  <MenuItem value="SMA50">SMA 50</MenuItem>
                  <MenuItem value="SMA200">SMA 200</MenuItem>
                  <MenuItem value="TREND">Trend</MenuItem>
                  <MenuItem value="TRAILING">Trailing days</MenuItem>
                  <MenuItem value="DATE">Date</MenuItem>
                  <MenuItem value="UPDATED">Updated</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  display: "inline",
                  flexGrow: 1,
                  marginBottom: "1rem"
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.filter}
                      onChange={event => this.handleFilterChange(event)}
                      value="owned"
                      color="secondary"
                    />
                  }
                  label="Show only stock I own"
                  labelPlacement="end"
                />
              </div>
              <AddStock
                user={this.state.user}
                myStocks={stocks}
                loading={loading}
              />
            </div>
            <ResponsiveTable
              columns={columns}
              data={Object.entries(stocks)
                .sort((a, b) => this.sortStock(a[1], b[1], sortColumn))
                .filter(stock =>
                  this.state.filter === "owned" ? stock[1].owned : true
                )
                .map(this.mapStocksToGrid)}
              tableBreakpoints={["xs", "sm"]}
              listBreakpoints={["md", "lg", "xl"]}
              noContentText=" "
            />
          </>
        ) : null}

        {!loading && Object.keys(stocks).length < 1 ? (
          <div style={{ textAlign: "center" }}>
            <strong>
              Welcome{" " + user.username || ""}, add your first stock to watch{" "}
              <Emoji symbol="👆" />
            </strong>
          </div>
        ) : null}
      </>
    );
  }
}

const timeFromRun = runTime => {
  let time = new Date(Date.now() - runTime);
  let days = Math.floor(time / (1000 * 60 * 60 * 24));
  let hours = Math.floor(time / (1000 * 60 * 60));
  let minutes = Math.floor(time / (1000 * 60));
  if (days > 2) {
    return days + " days ago";
  } else if (hours > 0) {
    return hours + " hour" + (hours === 1 ? "" : "s") + " ago";
  } else if (minutes > 0) {
    return minutes + " minute" + (minutes === 1 ? "" : "s") + " ago";
  } else {
    return "Just now";
  }
};

const timeToRun = () => {
  // run every 12 hours with a 3 hour offset, i.e. 03:00 UTC
  let hours = 12 - new Date().getUTCHours() + 2;
  let minutes = 60 - new Date().getMinutes();
  if (hours > 0) return hours + " hours";
  else return minutes + " minutes";
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(props => (
  <AuthUserContext.Consumer>
    {authUser => (
      <MixpanelConsumer>
        {mixpanel => (
          <Stocks {...props} authUser={authUser} mixpanel={mixpanel} />
        )}
      </MixpanelConsumer>
    )}
  </AuthUserContext.Consumer>
));
