import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { MixpanelConsumer } from "react-mixpanel";

import { auth } from "../firebase";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null
};

const textFieldCSS = {
  width: 250,
  marginRight: 25
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    auth
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.props.mixpanel.track("Password Changed");
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{ display: "block" }}>
          <TextField
            id="passwordOne"
            label="New Password"
            type="password"
            required
            value={passwordOne}
            onChange={event =>
              this.setState(byPropKey("passwordOne", event.target.value))
            }
            margin="normal"
            autoComplete="new-password"
            style={textFieldCSS}
          />
          <TextField
            id="passwordTwo"
            label="Confirm New Password"
            type="password"
            required
            value={passwordTwo}
            onChange={event =>
              this.setState(byPropKey("passwordTwo", event.target.value))
            }
            margin="normal"
            autoComplete="new-password"
            style={textFieldCSS}
          />
        </div>
        <Button
          disabled={isInvalid}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Change My Password
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

// export default PasswordChangeForm;

export default props => (
  <MixpanelConsumer>
    {mixpanel => <PasswordChangeForm {...props} mixpanel={mixpanel} />}
  </MixpanelConsumer>
);
