import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MixpanelConsumer } from "react-mixpanel";
import Button from "@material-ui/core/Button";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ShareIcon from "@material-ui/icons/Share";

import AuthUserContext from "./AuthUserContext";
import Emoji from "./Emoji";
import Loading from "./Loading";
import withAuthorization from "./withAuthorization";
import * as routes from "../constants/routes";
import { db } from "../firebase";
import * as utils from "../utils";

/**

 * TODO: support if payment never comes
 * TODO: add GA
 */
class StripeSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null
    };
  }

  componentDidMount() {
    this.props.mixpanel.track("Premium Pay Success");
    db.listenOnUpdatedUser(this.props.authUser.uid, user => {
      if (utils.isUserASubscriber(user.val())) {
        this.props.mixpanel.track("Premium Pay Fulfilled");
      }
      this.setState({ user: user.val() });
    });
  }

  render() {
    const user = this.state.user;
    const isUserASubscriber = utils.isUserASubscriber(user);

    return (
      <>
        <div
          className="landingProblems"
          style={{
            fontSize: "x-large",
            textAlign: "center",
            margin: "1rem"
          }}
        >
          {isUserASubscriber ? (
            <>
              <p>
                <strong>
                  Payment was successful{" "}
                  <Emoji symbol="🎉" label="A party popper" />
                </strong>
              </p>
              <p>
                Thank you so much <Emoji symbol="🙏" label="Praying hands" />
              </p>
            </>
          ) : (
            <>
              <p>
                <strong>Laundring the money..</strong>
              </p>
              <Loading />
            </>
          )}
        </div>

        {isUserASubscriber && (
          <div
            style={{ display: "flex", flexWrap: "wrap", textAlign: "center" }}
          >
            <div className="landingEmoji payEmoji">
              <Emoji symbol="💁‍️" label="A finger pointing right" />
              <p>I want to spread the word</p>
              <Button
                color="secondary"
                style={{ margin: "0.6rem " }}
                variant="contained"
                size="large"
                target="_blank"
                href="https://twitter.com/intent/tweet?text=Yay, I just subscribed to @chekrapp to let the robots keep track of my stocks 🤖 Check it out www.chekr.app!&url=www.chekr.app"
              >
                <ShareIcon style={{ marginRight: "1rem" }} />
                TWEET YOUR SUCCESS
              </Button>
            </div>
            <div className="landingEmoji payEmoji">
              <Emoji symbol="🚀" label="A rocket" />
              <p>I want to add more stocks</p>
              <Button
                color="primary"
                style={{ margin: "0.6rem " }}
                variant="contained"
                size="large"
                component={Link}
                to={routes.STOCKS}
              >
                <ShowChartIcon style={{ marginRight: "1rem" }} />
                GO TO MY STOCKS
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(props => (
  <AuthUserContext.Consumer>
    {authUser => (
      <MixpanelConsumer>
        {mixpanel => (
          <StripeSuccess {...props} authUser={authUser} mixpanel={mixpanel} />
        )}
      </MixpanelConsumer>
    )}
  </AuthUserContext.Consumer>
));
