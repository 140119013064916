import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ExploreIcon from "@material-ui/icons/Explore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShowChartIcon from "@material-ui/icons/ShowChart";

import AuthUserContext from "./AuthUserContext";
import Emoji from "./Emoji";
import SimpleMenu from "./SimpleMenu";

import * as routes from "../constants/routes";

const buttonCSS = {
  float: "right"
};

const linkCSS = {
  color: "white",
  textDecorationLine: "none"
};

const iconCSS = {
  marginRight: "4px"
};

const Navigation = () => {
  const authUser = useContext(AuthUserContext);

  return (
    <div>
      <AppBar position="static" color="primary" style={{ flexGrow: "1" }}>
        <Toolbar>
          <Typography
            variant="h5"
            color="inherit"
            style={{
              marginRight: "20px"
            }}
          >
            <Link to={routes.LANDING} style={linkCSS}>
              <Emoji symbol="🤖" /> Chekr
            </Link>
          </Typography>
          <div style={{ flexGrow: 1 }}>
            {authUser ? (
              <NavigationAuth authUser={authUser} />
            ) : (
              <NavigationNonAuth />
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const NavigationAuth = ({ authUser }) => (
  <>
    <Gravatar
      email={authUser.email}
      size={40}
      style={{
        verticalAlign: "middle",
        float: "right",
        borderRadius: "50%"
      }}
    />
    <Hidden smUp>
      <SimpleMenu />
    </Hidden>
    <Hidden xsDown>
      <SimpleMenu big />
      <Button
        color="inherit"
        style={buttonCSS}
        component={Link}
        to={routes.STOCKS}
      >
        <ShowChartIcon style={iconCSS} />
        MY STOCKS
      </Button>
      <Button
        color="inherit"
        style={buttonCSS}
        component={Link}
        to={routes.HOME}
      >
        <ExploreIcon style={iconCSS} />
        EXPLORE
      </Button>
    </Hidden>
  </>
);

const NavigationNonAuth = () => (
  <>
    <Button
      color="inherit"
      style={buttonCSS}
      component={Link}
      to={routes.SIGN_IN}
    >
      <AccountCircleIcon style={iconCSS} />
      SIGN IN
    </Button>
    <Button color="inherit" style={buttonCSS} component={Link} to={routes.HOME}>
      <ExploreIcon style={iconCSS} />
      EXPLORE
    </Button>
  </>
);

export default Navigation;
