import React from "react";
import { Switch, Route } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import teal from "@material-ui/core/colors/teal";
import pink from "@material-ui/core/colors/pink";

import Navigation from "./Navigation";
import LandingPage from "./Landing";
import SignUpPage from "./SignUp";
import SignInPage from "./SignIn";
import PasswordForgetPage from "./PasswordForget";
import HomePage from "./Home";
import Footer from "./Footer";
import Stocks from "./Stocks";
import AccountPage from "./Account";
import AdminPage from "./Admin";
import StripeSuccess from "./StripeSuccess";
import StripeCanceled from "./StripeCanceled";
import NotFound from "./NotFound";
import UpdateSnackbar from "./UpdateSnackbar";
import withAuthentication from "./withAuthentication";

import * as routes from "../constants/routes";

import "./App.css";

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: pink
  },
  typography: {
    // https://material-ui.com/style/typography#migration-to-typography-v2
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: "0.6rem"
      },
      head: {
        fontWeight: "bold",
        fontSize: "14px",
        color: "black"
      },
      body: {
        fontSize: "14px"
      }
    },
    MuiTypography: {
      body1: {
        // fix for the opacity in the expandable list
        opacity: "1 !important"
      }
    }
  }
});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <div className="app">
      <Navigation className="nav" />
      <UpdateSnackbar />
      <div className="content">
        <div
          style={{
            maxWidth: "1000px",
            margin: "auto",
            marginBottom: "3rem",
            padding: "2rem"
          }}
        >
          <Switch>
            <Route exact path={routes.LANDING} component={LandingPage} />
            <Route exact path={routes.SIGN_UP} component={SignUpPage} />
            <Route exact path={routes.SIGN_IN} component={SignInPage} />
            <Route
              exact
              path={routes.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />
            <Route exact path={routes.HOME} component={HomePage} />
            <Route exact path={routes.STOCKS} component={Stocks} />
            <Route exact path={routes.ADMIN} component={AdminPage} />
            <Route exact path={routes.ACCOUNT} component={AccountPage} />
            <Route
              exact
              path={routes.PAYMENT_SUCCESS}
              component={StripeSuccess}
            />
            <Route
              exact
              path={routes.PAYMENT_CANCELED}
              component={StripeCanceled}
            />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  </MuiThemeProvider>
);

export default withAuthentication(App);
