import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { MixpanelConsumer } from "react-mixpanel";
import AuthUserContext from "./AuthUserContext";

import Emoji from "./Emoji";
import StripeCheckout from "./StripeCheckout";
import IntegrationReactSelect from "./IntegrationReactSelect.js";
import { db } from "../firebase";
import * as utils from "../utils";

const AMOUNT_OF_STOCKS_FOR_FREE = 5;

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  symbol: "",
  label: "",
  error: null
};

class AddStock extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    // this.validateInput = this.validateInput.bind(this);
    this.getStockFromSymbol = this.getStockFromSymbol.bind(this);
    this.doIWatchThisStock = this.doIWatchThisStock.bind(this);
  }

  componentDidMount() {
    db.onceGetAllStocks().then(stocks => {
      this.setState({
        stocks: stocks.val()
      });
    });
  }

  // validateInput = symbol => {
  //   //if (symbol === "") return true;
  //   return this.doIWatchThisStock(symbol);
  // };

  doIWatchThisStock = symbol => {
    return (
      this.props.myStocks &&
      Object.values(this.props.myStocks)
        .map(stock => stock.symbol)
        .includes(symbol)
    );
  };

  getStockFromSymbol = symbol => {
    const stocks = this.state.stocks;
    return (
      stocks &&
      Object.values(stocks)
        // .filter(stock => stock.error !== "INCORRECT_SYMBOL")
        .filter(stock => stock.symbol === symbol)
      // .map(stock => stock.symbol)
      // .includes(symbol)
    );
  };

  onSubmit = event => {
    event.preventDefault();

    const { symbol } = this.state;
    if (symbol === "") {
      this.setState({ error: { message: "🧐 Adding what?" } });
      return;
    }
    this.setState({ ...INITIAL_STATE });

    // db.doAddStock(this.props.authUser.uid, symbol)
    //   .then(() => {
    db.doAddStockToUser(this.props.authUser.uid, symbol)
      .then(() => {
        this.props.mixpanel.track("Stock Added");
        this.props.mixpanel.people.increment({
          "Number of Stocks": 1
        });
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });
    // })
    // .catch(error => {
    //   this.setState(byPropKey("error", error));
    // });
  };

  render() {
    const { symbol, error, stocks } = this.state;

    const isUserASubscriber = utils.isUserASubscriber(this.props.user);
    const getStockFromSymbol = this.getStockFromSymbol(symbol);
    const doIWatchThisStock = this.doIWatchThisStock(symbol);
    const canAddMoreFreeStocks =
      this.props.myStocks &&
      Object.keys(this.props.myStocks).length < AMOUNT_OF_STOCKS_FOR_FREE;
    const isInvalid =
      (!canAddMoreFreeStocks && !isUserASubscriber) ||
      doIWatchThisStock ||
      (getStockFromSymbol &&
        getStockFromSymbol[0] &&
        getStockFromSymbol[0].error === "INCORRECT_SYMBOL") ||
      symbol.includes(" ");

    return (
      <>
        <div
          style={{
            minHeight: "76px",
            maxWidth: "400px",
            width: "100%",
            marginLeft: "auto"
          }}
        >
          {stocks && !this.props.loading ? (
            <>
              <form
                onSubmit={this.onSubmit}
                style={{
                  display: "flex",
                  paddingRight: "0.6rem",
                  marginTop: "-0.6rem"
                }}
              >
                <IntegrationReactSelect
                  stocks={Object.values(stocks).filter(
                    stock =>
                      stock.error !== "INCORRECT_SYMBOL" &&
                      stock.error !== "UNKNOWN_ERROR" &&
                      !this.doIWatchThisStock(stock.symbol)
                  )}
                  onChange={change => {
                    this.setState({
                      symbol: change ? change.value.toUpperCase() : "",
                      label: change ? change.label : ""
                    });
                    if (this.state.error) {
                      this.setState({ error: null });
                    }
                  }}
                  value={{
                    label: this.state.label,
                    value: this.state.symbol
                  }}
                />
                <Tooltip title="Add">
                  <Fab
                    color="secondary"
                    type="submit"
                    disabled={isInvalid}
                    aria-label="Add"
                    style={{ marginLeft: "1rem" }}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </form>
            </>
          ) : null}
          <div style={{ paddingTop: "0.6rem", maxWidth: "330px" }}>
            {error && <p>{error.message}</p>}
            {doIWatchThisStock ? (
              `‍‍🤷‍ You already watch this stock`
            ) : getStockFromSymbol &&
              getStockFromSymbol[0] &&
              getStockFromSymbol[0].error === "INCORRECT_SYMBOL" ? (
              `❌ ${symbol} is not a valid symbol`
            ) : getStockFromSymbol && getStockFromSymbol[0] ? (
              // "✅ " + symbol + " is already indexed by Chekr"
              ""
            ) : symbol.includes(" ") ? (
              `🧐 Replace the space ' ' with a hyphen '-'`
            ) : symbol !== "" ? (
              <span>
                <Emoji symbol="🆕" /> {symbol} is new to Chekr, make sure it{" "}
                <a
                  href={"https://finance.yahoo.com/quote/" + symbol}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  a valid stock symbol
                </a>
                {!symbol.includes(".") ? (
                  <p>
                    <Emoji symbol="🙋" />‍ If you want to add a non-US stock,
                    please append the market symbol, like '.ST' for Stockholm or
                    '.CO' for Copenhagen
                  </p>
                ) : (
                  ""
                )}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {canAddMoreFreeStocks ? null : isUserASubscriber ? null : (
          <div style={{ width: "100%", textAlign: "center" }}>
            <StripeCheckout />
          </div>
        )}
      </>
    );
  }
}

export default props => (
  <AuthUserContext.Consumer>
    {authUser => (
      <MixpanelConsumer>
        {mixpanel => (
          <AddStock {...props} authUser={authUser} mixpanel={mixpanel} />
        )}
      </MixpanelConsumer>
    )}
  </AuthUserContext.Consumer>
);
