import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const prodConfig = {
  apiKey: "AIzaSyBl---_JbeoQoJ1mYyj3saxzCaUTjbkFd8",
  authDomain: "react-firebase-99c13.firebaseapp.com",
  databaseURL: "https://react-firebase-99c13.firebaseio.com",
  projectId: "react-firebase-99c13",
  storageBucket: "react-firebase-99c13.appspot.com",
  messagingSenderId: "298837537047"
};

const devConfig = {
  apiKey: "AIzaSyCFs73q47EZ4qHnQdanMtsKhMyjdi8RN4o",
  authDomain: "chekr-dev.firebaseapp.com",
  databaseURL: "https://chekr-dev.firebaseio.com",
  projectId: "chekr-dev",
  storageBucket: "chekr-dev.appspot.com",
  messagingSenderId: "594415954076"
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();

export { db, auth };
