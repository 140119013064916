import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { MixpanelConsumer } from "react-mixpanel";

import Emoji from "./Emoji";
import { auth, db } from "../firebase";
import * as routes from "../constants/routes";

const SignUpPage = ({ history }) => (
  <div>
    <h2>Sign Up</h2>
    <MixpanelConsumer>
      {mixpanel => <SignUpForm mixpanel={mixpanel} history={history} />}
    </MixpanelConsumer>
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const textFieldCSS = {
  width: 250,
  marginRight: 25
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;

    const { history } = this.props;

    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your own accessible Firebase Database too
        this.props.mixpanel.identify(authUser.user.uid);
        // this.props.mixpanel.alias(authUser.user.uid);
        this.props.mixpanel.track("Signed Up");
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            history.push(routes.STOCKS);
          })
          .catch(error => {
            this.setState(byPropKey("error", error));
          });
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{ display: "block" }}>
          <TextField
            id="name"
            label="Full Name"
            required
            autoFocus
            value={username}
            type="text"
            onChange={event =>
              this.setState(byPropKey("username", event.target.value))
            }
            margin="normal"
            style={textFieldCSS}
          />
          <TextField
            id="email"
            label="Email"
            type="text"
            required
            value={email}
            onChange={event =>
              this.setState(
                byPropKey("email", event.target.value.toLowerCase())
              )
            }
            margin="normal"
            style={textFieldCSS}
          />
        </div>
        <div style={{ display: "block" }}>
          <TextField
            id="passwordOne"
            label="New Password"
            type="password"
            required
            value={passwordOne}
            onChange={event =>
              this.setState(byPropKey("passwordOne", event.target.value))
            }
            margin="normal"
            autoComplete="new-password"
            style={textFieldCSS}
          />
          <TextField
            id="passwordTwo"
            label="Confirm New Password"
            type="password"
            required
            value={passwordTwo}
            onChange={event =>
              this.setState(byPropKey("passwordTwo", event.target.value))
            }
            margin="normal"
            autoComplete="new-password"
            style={textFieldCSS}
          />
        </div>
        <Button
          disabled={isInvalid}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Sign Up
        </Button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p style={{ marginTop: "1rem" }}>
    No account? Go ahead and <Emoji symbol="👉" />{" "}
    <Link to={routes.SIGN_UP}>Sign Up</Link>
  </p>
);

export default withRouter(SignUpPage);

// export default props => (
//   <MixpanelConsumer>
//     {mixpanel => withRouter(<SignUpPage {...props} mixpanel={mixpanel} />)}
//   </MixpanelConsumer>
// );

export { SignUpForm, SignUpLink };
