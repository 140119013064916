import React from "react";
import { MixpanelConsumer } from "react-mixpanel";

import AuthUserContext from "./AuthUserContext";
import { db, firebase } from "../firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          // console.log(authUser);
          this.props.mixpanel.identify(authUser.uid);
          // this.props.mixpanel.register({
          //   creationTime: authUser.metadata.creationTime
          // });
          // this.props.mixpanel.register_once({
          //   lastSignInTime: authUser.metadata.lastSignInTime
          // });

          db.doUpdateUser(authUser.uid, {
            created_at: new Date(authUser.metadata.creationTime).getTime(),
            last_sign_in_at: new Date(
              authUser.metadata.lastSignInTime
            ).getTime()
          });

          this.props.mixpanel.people.set({
            $email: authUser.email,
            $created: authUser.metadata.creationTime,
            $last_login: authUser.metadata.lastSignInTime
          });
          window.$crisp.push(["safe", true]);
          window.$crisp.push(["set", "user:email", [authUser.email]]);
        }

        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null });
      });
    }

    render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  // return WithAuthentication;

  return props => (
    <MixpanelConsumer>
      {mixpanel => <WithAuthentication {...props} mixpanel={mixpanel} />}
    </MixpanelConsumer>
  );
};

export default withAuthentication;

// export default props => (
//   <MixpanelConsumer>
//     {mixpanel => <withAuthentication {...props} mixpanel={mixpanel} />}
//   </MixpanelConsumer>
// );
