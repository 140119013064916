import React, { Component } from "react";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";

import AuthUserContext from "./AuthUserContext";
// import { PasswordForgetForm } from "./PasswordForget";
import Emoji from "./Emoji";
import PasswordChangeForm from "./PasswordChange";
import withAuthorization from "./withAuthorization";

import { db } from "../firebase";
import * as routes from "../constants/routes";
import * as utils from "../utils";

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null
    };
  }

  componentDidMount() {
    db.onceGetUser(this.props.authUser.uid).then(user => {
      this.setState({ user: user.val() });
    });
  }

  render() {
    const user = this.state.user;
    const isUserASubscriber = utils.isUserASubscriber(user);

    return (
      <div>
        <h2>My Account</h2>
        <h3>Gravatar</h3>
        <Gravatar email={this.props.authUser.email} />
        <h3>Name</h3>
        {this.state.user && this.state.user.username}
        <h3>Email</h3>
        {this.props.authUser.email}
        {/* <PasswordForgetForm /> */}
        <h3>Account Type</h3>
        {isUserASubscriber ? (
          <>
            Premium <Emoji symbol="👑" />
            <br />
            <Button
              variant="contained"
              size="small"
              href="mailto:niklas@chekr.app?subject=I would like to unsubscribe"
              style={{ marginTop: "0.6rem" }}
            >
              <EmailIcon style={{ marginRight: "1rem" }} />
              Unsubscribe
            </Button>
          </>
        ) : (
          "Free"
        )}
        <h3>Change password</h3>
        <PasswordChangeForm />
        {user && user.email === "niklas.lavrell@gmail.com" ? (
          <div style={{ display: "block", marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.ADMIN}
            >
              <span style={{ color: "black" }}>Admin</span>
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(props => (
  <AuthUserContext.Consumer>
    {authUser => <AccountPage {...props} authUser={authUser} />}
  </AuthUserContext.Consumer>
));
