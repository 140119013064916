import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./index.css";
import App from "./components/App";
//import * as serviceWorker from "./serviceWorker";

import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
import createHistory from "history/createBrowserHistory";

const MIXPANEL_PROD = "368fbe1cf000135c6f2db44229bb1392";
const MIXPANEL_DEV = "ca5e46583e3f1f4bfe0877cbf79e3e20";

const GA_PROD = "UA-47913005-2";
const GA_DEV = "UA-47913005-3";

if (process.env.REACT_APP_ENV === "production") {
  mixpanel.init(MIXPANEL_PROD);
  ReactGA.initialize(GA_PROD);
} else {
  mixpanel.init(MIXPANEL_DEV);
  ReactGA.initialize(GA_DEV);
}

const history = createHistory();
var pastLocation = window.location.pathname;
// fix for https://github.com/ReactTraining/history/issues/636
history.listen((location, action) => {
  if (location.pathname !== pastLocation) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
  pastLocation = location.pathname;
});

ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <MixpanelProvider mixpanel={mixpanel}>
    <Router history={history}>
      <App />
    </Router>
  </MixpanelProvider>,
  document.getElementById("root")
);

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     console.log("serviceWorker update registration", registration);
//   },
//   onSuccess: registration => {
//     console.log("serviceWorker success registration", registration);
//   }
// });
