import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";

import * as serviceWorker from "../serviceWorker";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2
  }
});

class UpdateSnackbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      update: null,
      sucess: null,
      message: "A message",
      ctaMessage: "OK"
    };

    this.handleClick = this.handleClick.bind(this);
    this.reload = this.reload.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  reload = ctaMessage => {
    if (window && ctaMessage === "REFRESH") window.location.reload();
  };

  handleClose = (event, reason) => {
    console.log("event", event);
    console.log("reason", reason);
    // possible: timeout, clickaway
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  componentDidMount() {
    serviceWorker.unregister();
    // serviceWorker.register({
    //   onUpdate: registration => {
    //     // console.log("serviceWorker update", registration);
    //     this.setState({
    //       open: true,
    //       message: "🤖Chekr is updated, refresh now.",
    //       ctaMessage: "REFRESH"
    //     });
    //   },
    //   onSuccess: registration => {
    //     // console.log("serviceWorker success", registration);
    //     this.setState({
    //       open: true,
    //       message: "You can now use 🤖Chekr offline.",
    //       ctaMessage: "ALRIGHT"
    //     });
    //   }
    // });
  }

  render() {
    // const { classes } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.open}
        autoHideDuration={18000}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{this.state.message}</span>}
        action={
          <Button
            key="cta"
            color="secondary"
            size="small"
            onClick={this.reload(this.state.ctaMessage)}
          >
            {this.state.ctaMessage}
          </Button>
        }
      />
    );
  }
}

UpdateSnackbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UpdateSnackbar);
