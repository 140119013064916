import React from "react";
import ReactTooltip from "react-tooltip";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Emoji from "./Emoji";

class StrategySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      strategy: "sma-50-200"
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "sma-50-200") {
      this.setState({ error: null });
    } else {
      this.setState({ error: "Only SMA 50/200 is supported at the moment." });
    }
  };

  render() {
    return (
      <div
        style={{ display: "inline", marginBottom: "1rem", marginRight: "1rem" }}
      >
        <div
          style={{ display: "inline" }}
          data-tip={
            "SMA 50/200 is the only available strategy at the moment.<br />" +
            "Want more strategies? Please let me know via feedback link in the footer 🙏"
          }
          data-for="strategy"
        >
          <FormControl>
            <InputLabel shrink htmlFor="strategy-label">
              Strategy <Emoji symbol="🚨" />
            </InputLabel>
            <Select
              value={this.state.strategy}
              onChange={this.handleChange}
              inputProps={{
                name: "strategy-input",
                id: "strategy-label"
              }}
              name="strategy-select"
              disabled
            >
              <MenuItem value="sma-50-200">SMA 50/200</MenuItem>
              <MenuItem value="sma-10-40">
                <em>SMA 10/40 (coming soon)</em>
              </MenuItem>
              <MenuItem value="rsi-30-70">
                <em>RSI 30/70 (coming soon)</em>
              </MenuItem>
              <MenuItem value="macd-30-70">
                <em>MACD 30/70 (coming soon)</em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <ReactTooltip id="strategy" multiline />
        {this.state.error ? (
          <div style={{ display: "inline" }}>
            {this.state.error}
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.handleChange({
                  target: { name: "strategy", value: "sma-50-200" }
                })
              }
            >
              Ah, okay
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default StrategySelect;
