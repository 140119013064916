import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { auth } from "../firebase";
import * as routes from "../constants/routes";

const PasswordForgetPage = () => (
  <div>
    <h2>Forgot Password</h2>
    <PasswordForgetForm />
  </div>
);

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  error: null
};

const textFieldCSS = {
  width: 250,
  marginRight: 25
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{ display: "block" }}>
          <TextField
            id="email"
            label="Email"
            type="text"
            required
            value={this.state.email}
            onChange={event =>
              this.setState(byPropKey("email", event.target.value))
            }
            margin="normal"
            style={textFieldCSS}
          />
        </div>
        <Button
          disabled={isInvalid}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Reset My Password
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p style={{ marginTop: "1rem" }}>
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink };
