import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MixpanelConsumer } from "react-mixpanel";
import Button from "@material-ui/core/Button";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EmailIcon from "@material-ui/icons/Email";

import Emoji from "./Emoji";
import withAuthorization from "./withAuthorization";
import * as routes from "../constants/routes";

/**

 * TODO: add GA
 */
class StripeCanceled extends Component {
  componentDidMount() {
    this.props.mixpanel.track("Premium Pay Canceled");
  }

  render() {
    return (
      <>
        <div
          className="landingProblems"
          style={{
            fontSize: "x-large",
            textAlign: "center",
            margin: "1rem"
          }}
        >
          <p>
            <strong>Payment was canceled</strong>
          </p>
          <p>What happened?</p>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", textAlign: "center" }}>
          <div className="landingEmoji payEmoji">
            <Emoji symbol="😟" label="A worried face" />
            <p>Something went wrong</p>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              target="_blank"
              href="mailto:niklas@chekr.app?subject=Something went wrong with my payment"
            >
              <EmailIcon style={{ marginRight: "1rem" }} />
              LET ME KNOW
            </Button>
          </div>
          <div className="landingEmoji payEmoji">
            <Emoji symbol="😌" label="A relieved face" />
            <p>I just got cold feet</p>
            <Button
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              to={routes.STOCKS}
            >
              <ShowChartIcon style={{ marginRight: "1rem" }} />
              BACK TO FREE STOCKS
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(props => (
  <MixpanelConsumer>
    {mixpanel => <StripeCanceled {...props} mixpanel={mixpanel} />}
  </MixpanelConsumer>
));
