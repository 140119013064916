import React from "react";
import { Link } from "react-router-dom";

import Emoji from "./Emoji";
import * as routes from "../constants/routes";

const NotFound = () => (
  <div>
    <h2>Not Found</h2>
    <p>
      Hey there, you seem lost.. I don't know what this page is{" "}
      <Emoji symbol="🤷‍" />
    </p>
    <strong>
      <p>
        You might want to go back to the <Emoji symbol="👉" />
        <Link to={routes.LANDING}>Landing Page</Link>
      </p>
    </strong>
  </div>
);

export default NotFound;
