import { db } from "./firebase";

// User API

export const doCreateUser = (id, username, email) =>
  db.ref(`users/${id}`).set({
    username,
    email
  });

export const doUpdateUser = (id, update) =>
  db.ref(`users/${id}`).update(update);

export const onceGetUsers = () => db.ref("users").once("value");

export const onceGetUser = id => db.ref(`users/${id}`).once("value");

export const listenOnUpdatedUser = (id, callback) =>
  db.ref(`users/${id}`).on("value", data => {
    callback(data);
  });

// export const doAddStock = (id, symbol) =>
//   db.ref(`users/${id}/stocks/${symbol}`).set({
//     symbol
//   });

export const doAddStock = (id, symbol) =>
  db.ref(`stocks/${symbol}`).update({
    symbol: symbol,
    lastAddedDate: Date.now(),
    lastAddedBy: id
  });

export const doAddStockToUser = (id, symbol) =>
  db
    .ref(`users/${id}/stocks`)
    .push()
    .set({
      symbol: symbol,
      addedDate: Date.now()
    });

export const doUpdateStock = (userId, stockId, update) =>
  db.ref(`users/${userId}/stocks/${stockId}`).update(update);

export const doRemoveStock = (id, key) =>
  db.ref(`users/${id}/stocks/${key}`).remove();

export const onceGetStocks = id => db.ref(`users/${id}/stocks`).once("value");

export const onceGetAllStocks = id => db.ref(`stocks`).once("value");

export const listenOnNewStocks = (id, callback) =>
  db.ref(`users/${id}/stocks`).on("child_added", data => {
    callback(data);
  });

export const listenOnUpdatedStocks = (id, callback) =>
  db.ref(`users/${id}/stocks`).on("child_changed", data => {
    callback(data);
  });

export const listenOnRemovedStocks = (id, callback) =>
  db.ref(`users/${id}/stocks`).on("child_removed", data => {
    callback(data);
  });
