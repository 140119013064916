module.exports.getFlagFromStock = stock => {
  if (stock.error || stock.new) return "";
  if (stock.symbol.includes(".ST")) return "🇸🇪";
  if (stock.symbol.includes(".HE")) return "🇫🇮";
  if (stock.symbol.includes(".OL")) return "🇳🇴";
  if (stock.symbol.includes(".CO")) return "🇩🇰";
  if (stock.symbol.includes(".DE")) return "🇩🇪";
  if (stock.symbol.includes(".TO")) return "🇨🇦";
  if (stock.symbol.includes(".V")) return "🇨🇦";
  if (stock.symbol.includes(".PA")) return "🇫🇷";
  if (stock.symbol.includes(".L")) return "🇬🇧";
  if (stock.symbol.includes(".MI")) return "🇮🇹";
  if (stock.symbol.includes(".AS")) return "🇳🇱";
  if (stock.symbol.includes("JPY")) return "💴";
  if (stock.symbol.includes("GBP")) return "💷";
  if (stock.symbol.includes("EUR")) return "💶";
  if (stock.symbol.includes("USD")) return "💵";
  return "🇺🇸";
};

module.exports.isUserASubscriber = user =>
  user &&
  ((user.payment && user.payment.type === "checkout_beta.session_succeeded") ||
    (user.created_at
      ? user.created_at < 1548979200000 // 2019-02-01
      : true));

const DEV_URL = `http://localhost:3000`;
const PROD_URL = `https://www.chekr.app`;
const URL = process.env.NODE_ENV === `production` ? PROD_URL : DEV_URL;

module.exports.getBaseUrl = () => URL;
