import React from "react";

const Footer = props => (
  <div
    style={{
      backgroundColor: "rgba(0, 149, 136, 0.75)",
      padding: "2rem",
      color: "white",
      display: "flex",
      flexShrink: 0,
      flexWrap: "wrap"
    }}
  >
    <span style={{ paddingRight: "1rem" }}>
      Made by{" "}
      <a
        href="https://twitter.com/niklaslavrell"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white" }}
      >
        <strong>@niklaslavrell</strong>
      </a>{" "}
      👨‍💻
    </span>
    <span style={{ paddingRight: "1rem" }}>
      Give me feedback at{" "}
      <a
        href="mailto:niklas@chekr.app?subject=Feedback for Chekr 🤗"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white" }}
      >
        <strong>niklas@chekr.app</strong>
      </a>{" "}
      🙏
    </span>
    <span style={{ paddingRight: "1rem" }}>
      Follow{" "}
      <a
        href="https://twitter.com/chekrapp"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white" }}
      >
        <strong>@chekrapp</strong>
      </a>{" "}
      for robot tweets 🤖
    </span>
    <span style={{ paddingRight: "1rem" }}>
      See{" "}
      <a
        href="https://getmakerlog.com/products/chekr"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white" }}
      >
        <strong>what's new</strong>
      </a>{" "}
      ✅
    </span>
  </div>
);

export default Footer;
