export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const HOME = "/explore";
export const STOCKS = "/stocks";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const PAYMENT_SUCCESS = "/success";
export const PAYMENT_CANCELED = "/canceled";
