import React, { Component } from "react";
import Gravatar from "react-gravatar";
import ResponsiveTable from "material-ui-next-responsive-table";

import AuthUserContext from "./AuthUserContext";
import withAuthorization from "./withAuthorization";
import { db } from "../firebase";
import Emoji from "./Emoji";

const columns = [
  {
    key: "gravatar",
    label: "Gravatar",
    primary: true,
    render: value => <Gravatar email={value} />
  },
  {
    key: "name",
    label: "Name"
  },
  {
    key: "email",
    label: "Email",
    primary: true
  },
  {
    key: "stocks",
    label: "Number of stocks"
  }
];

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null
      //   user: null
    };
  }

  componentDidMount() {
    db.onceGetUsers().then(snapshot => {
      this.setState({ users: snapshot.val() });
      //   db.onceGetUser(this.props.authUser.uid).then(user => {
      //     this.setState({ user: user.val() });
      //   });
    });
  }

  render() {
    const { users } = this.state;

    return (
      <>
        <h2>Admin</h2>
        <div style={{ marginBottom: "2rem" }}>
          <p>
            <Emoji symbol="🧙‍" />
            This admin page is only accessible by the bot masters.
          </p>
        </div>
        <h3>Users on Chekr</h3>
        <ResponsiveTable
          columns={columns}
          data={
            users &&
            Object.values(users).map(user => {
              return {
                gravatar: user.email || "",
                name: user.username || "",
                email: user.email || "",
                stocks: user.stocks ? Object.keys(user.stocks).length : "0"
              };
            })
          }
          tableBreakpoints={["xs", "sm"]}
          listBreakpoints={["md", "lg", "xl"]}
          noContentText=" "
        />
      </>
    );
  }
}

const authCondition = authUser =>
  !!authUser && authUser.email === "niklas.lavrell@gmail.com";

export default withAuthorization(authCondition)(props => (
  <AuthUserContext.Consumer>
    {authUser => <AdminPage {...props} authUser={authUser} />}
  </AuthUserContext.Consumer>
));
