import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import ExploreIcon from "@material-ui/icons/Explore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Divider from "@material-ui/core/Divider";
import { MixpanelConsumer } from "react-mixpanel";

import { auth } from "../firebase";
import * as routes from "../constants/routes";

const buttonCSS = {
  float: "right"
};

const linkCSS = {
  color: "black",
  textDecorationLine: "none"
};

const iconCSS = {
  marginRight: "5px"
};

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <>
        {this.props.big ? (
          <Button
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            color="inherit"
            onClick={this.handleClick}
            style={{
              float: "right",
              borderRadius: "50%",
              padding: "8px",
              minWidth: "36px"
            }}
          >
            <MoreVertIcon />
          </Button>
        ) : (
          <Button
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            color="inherit"
            onClick={this.handleClick}
            style={buttonCSS}
          >
            <MenuIcon style={iconCSS} />
            Menu
          </Button>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.big ? null : (
            <>
              <MenuItem onClick={this.handleClose}>
                <Link to={routes.HOME} style={linkCSS}>
                  <ListItemIcon>
                    <ExploreIcon />
                  </ListItemIcon>
                  <span style={{ verticalAlign: "top" }}>EXPLORE</span>
                </Link>
              </MenuItem>
              <MenuItem onClick={this.handleClose}>
                <Link to={routes.STOCKS} style={linkCSS}>
                  <ListItemIcon>
                    <ShowChartIcon />
                  </ListItemIcon>
                  <span style={{ verticalAlign: "top" }}>MY STOCKS</span>
                </Link>
              </MenuItem>
              <Divider style={{ margin: "5px" }} />
            </>
          )}
          <MenuItem onClick={this.handleClose}>
            <Link to={routes.ACCOUNT} style={linkCSS}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <span style={{ verticalAlign: "top" }}>ACCOUNT</span>
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() =>
              auth.doSignOut().then(this.props.mixpanel.track("Signed Out"))
            }
          >
            {/* <Link to={routes.SIGN_IN} style={linkCSS}> */}
            <ListItemIcon>
              <HighlightOffIcon />
            </ListItemIcon>
            <span style={{ verticalAlign: "top" }}>SIGN OUT</span>
            {/* </Link> */}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

// export default SimpleMenu;

export default props => (
  <MixpanelConsumer>
    {mixpanel => <SimpleMenu {...props} mixpanel={mixpanel} />}
  </MixpanelConsumer>
);
