const devConfig = {
  apiKey: `pk_test_YDmyqmJZgSx6nlxBLlhOq3xN`,
  plan: `plan_EbdMBNveL8FjIm` // 3 USD
  //plan: `plan_E6ou3vuaBlwdaO` // 29 SEK
};

const prodConfig = {
  apiKey: `pk_live_PF7ZVViaaSdtehuO9hqzQr9M`,
  plan: `plan_EbyNJWeUvFH4vR` // Monthly 3 USD
};

const config = process.env.NODE_ENV === `production` ? prodConfig : devConfig;

export const API_KEY = config.apiKey;
export const PLAN = config.plan;
