import React, { Component } from "react";
import { MixpanelConsumer } from "react-mixpanel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import AuthUserContext from "./AuthUserContext";
import Emoji from "./Emoji";
import * as routes from "../constants/routes";
import * as stripeConfig from "../constants/stripe";
import * as utils from "../utils";

/**

 * TODO: add GA
 */
class StripeCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      error: null
    };

    this.redirectToCheckout = this.redirectToCheckout.bind(this);
  }

  componentDidMount() {
    this.props.mixpanel.track("Premium Impression");
    const stripe = window.Stripe(stripeConfig.API_KEY, {
      betas: ["checkout_beta_4"]
    });
    this.setState({ stripe: stripe });
  }

  redirectToCheckout() {
    this.props.mixpanel.track("Premium Pay");

    const stripe = this.state.stripe;
    const url = utils.getBaseUrl();

    stripe
      .redirectToCheckout({
        items: [{ plan: stripeConfig.PLAN, quantity: 1 }],

        // Note that it is not guaranteed your customers will be redirected to this
        // URL *100%* of the time, it's possible that they could e.g. close the
        // tab between form submission and the redirect.
        // successUrl: "http://localhost:3000/success",
        // cancelUrl: "http://localhost:3000/canceled",
        successUrl: url + routes.PAYMENT_SUCCESS,
        cancelUrl: url + routes.PAYMENT_CANCELED,
        clientReferenceId: this.props.authUser.uid
      })
      .then(result => {
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer.
          this.setState({ error: result.error.message });
        }
      });
  }

  render() {
    const error = this.state.error;

    return (
      <Paper elevation={1} style={{ padding: "1rem", marginBottom: "1rem" }}>
        <p>
          <strong>
            Thank you for using Chekr <Emoji symbol="🙏" />
          </strong>
        </p>
        <p>
          I would love to continue running Chekr and add more features. To do so
          I need to buy some coffee <Emoji symbol="☕️" label="A coffee" /> and
          pay the robots <Emoji symbol="🤖" label="A robot" />
        </p>
        <p>
          To watch more stocks, please support me. Cancel anytime{" "}
          <Emoji symbol="😇" label="A smiling face with halo" />
        </p>
        <Button
          key="checkout-button"
          variant="contained"
          color="secondary"
          onClick={() => this.redirectToCheckout()}
          style={{ margin: "1rem" }}
        >
          <Emoji symbol="☕️" label="A coffee" /> ($3) / month
        </Button>
        {error && <p>{error}</p>}
      </Paper>
    );
  }
}

export default props => (
  <AuthUserContext.Consumer>
    {authUser => (
      <MixpanelConsumer>
        {mixpanel => (
          <StripeCheckout {...props} authUser={authUser} mixpanel={mixpanel} />
        )}
      </MixpanelConsumer>
    )}
  </AuthUserContext.Consumer>
);
