import React, { Component } from "react";
import ResponsiveTable from "material-ui-next-responsive-table";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import teal from "@material-ui/core/colors/teal";
import ReactTooltip from "react-tooltip";

// import AuthUserContext from "./AuthUserContext";
// import withAuthorization from "./withAuthorization";
import Loading from "./Loading";
import { db } from "../firebase";
import Emoji from "./Emoji";

import * as utils from "../utils";

const columns = [
  {
    key: "stock",
    // label: "Stock",
    label: {
      toUpperCase: () => {
        return (
          <>
            <div
              data-tip="Symbol for the stock or a pair of currencies"
              data-for="symbol"
            >
              STOCK
            </div>
            <ReactTooltip id="symbol" />
          </>
        );
      }
    },
    primary: true,
    render: value => <strong>{value}</strong>
  },
  {
    key: "sma50",
    // label: "SMA 50"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Simple Moving Avarage 50 days" data-for="sma50">
              SMA 50
            </div>
            <ReactTooltip id="sma50" />
          </>
        );
      }
    }
  },
  {
    key: "sma200",
    // label: "SMA 200"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Simple Moving Avarage 200 days" data-for="sma200">
              SMA 200
            </div>
            <ReactTooltip id="sma200" />
          </>
        );
      }
    }
  },
  {
    key: "trend",
    // label: "Trend",
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Trend according to the strategy" data-for="trend">
              TREND
            </div>
            <ReactTooltip id="trend" />
          </>
        );
      }
    },
    primary: true,
    render: value => <strong>{value}</strong>
  },
  {
    key: "trailing",
    // label: "Trailing days"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Amount of days with this trend" data-for="trailing">
              TRAILING DAYS
            </div>
            <ReactTooltip id="trailing" />
          </>
        );
      }
    }
  },
  {
    key: "watchers",
    // label: "Watchers"
    label: {
      toUpperCase: () => {
        return (
          <>
            <div data-tip="Users watching this symbol" data-for="watchers">
              WATCHERS
            </div>
            <ReactTooltip id="watchers" />
          </>
        );
      }
    }
  }
];

const styles = {
  avatar: {
    color: "#fff",
    backgroundColor: teal[500]
  }
};

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allStocks: {},
      users: null,
      user: null,
      loading: true,
      listOfPopularStocks: null,
      totalUsers: null,
      totalStocks: null
    };

    this.getListOfPopularStocks = this.getListOfPopularStocks.bind(this);
    this.mapStocksToGrid = this.mapStocksToGrid.bind(this);
  }

  mapStocksToGrid = stock => {
    return {
      stock: stock.name
        ? `${stock.name} (${stock.symbol}) ${utils.getFlagFromStock(stock)}`
        : `${stock.symbol} ${utils.getFlagFromStock(stock) || ``}`,
      sma50: stock.sma50 || "",
      sma200: stock.sma200 || "",
      trend:
        stock.trend && stock.trend === "up"
          ? "📈"
          : stock.trend === "down"
          ? "📉"
          : "",
      trailing: stock.trailingDays ? (
        <>
          <div
            data-tip={`Since ${
              stock.trailingDays > 199 ? `eternity? 🤷‍` : stock.trailingSince
            }`}
            data-for={`since-${stock.symbol}`}
          >
            {stock.trailingDays > 199 ? `200+` : stock.trailingDays}
          </div>
          <ReactTooltip id={`since-${stock.symbol}`} />
        </>
      ) : (
        ""
      ),
      watchers: (
        <Avatar className={this.props.classes.avatar}>
          {stock.watchers || ""}
        </Avatar>
      )
    };
  };

  getListOfPopularStocks = (users, allStocks) => {
    let stocks = {};
    let totalWatching = 0;
    users &&
      Object.keys(users).map(
        userKey =>
          !!users[userKey].stocks &&
          Object.keys(users[userKey].stocks).map(stockKey => {
            totalWatching++;
            if (stocks[users[userKey].stocks[stockKey].symbol]) {
              stocks[users[userKey].stocks[stockKey].symbol].watchers++;
            } else {
              stocks[users[userKey].stocks[stockKey].symbol] =
                users[userKey].stocks[stockKey];
              stocks[users[userKey].stocks[stockKey].symbol]["watchers"] = 1;
            }
            return null;
          })
      );
    this.setState({
      totalUsers: users && Object.keys(users).length,
      totalStocks: totalWatching
    });
    Object.values(stocks).map(stock => {
      Object.values(allStocks)
        .filter(allStock => stock.symbol === allStock.symbol)
        .map(allStock => {
          stock.name = allStock.name;
          stock.closingDate = allStock.closingDate;
          stock.runDate = allStock.runDate;
          stock.sma50 = allStock.sma50;
          stock.sma100 = allStock.sma100;
          stock.sma200 = allStock.sma200;
          stock.trailingDays = allStock.trailingDays;
          stock.trailingSince = allStock.trailingSince;
          stock.trend = allStock.trend;
          stock.error = allStock.error;
          return null;
        });
      return null;
    });
    return Object.values(stocks)
      .filter(stock => !stock.error && stock.runDate)
      .sort((a, b) => {
        if (b.watchers > a.watchers) return 1;
        if (a.watchers > b.watchers) return -1;
        if (b.sma50 && b.sma200 && !a.sma50 && !a.sma200) return 1;
        if (a.sma50 && a.sma200 && !b.sma50 && !b.sma200) return -1;
        if (b.sma50 && !a.sma50) return 1;
        if (a.sma50 && !b.sma50) return -1;
        return 0;
      });
  };

  componentDidMount() {
    db.onceGetAllStocks()
      .then(stockSnapshot => {
        this.setState({ allStocks: stockSnapshot.val() });
      })
      .then(() => {
        db.onceGetUsers().then(userSnapshot => {
          this.setState({
            users: userSnapshot.val(),
            loading: false,
            listOfPopularStocks: this.getListOfPopularStocks(
              userSnapshot.val(),
              this.state.allStocks
            )
          });
          this.props.authUser && // this makes it not run in this page...
            db.onceGetUser(this.props.authUser.uid).then(user => {
              this.setState({ user: user.val() });
            });
        });
      });
  }

  render() {
    const { users, loading, listOfPopularStocks } = this.state;

    return (
      <div>
        <h2>Explore</h2>
        <div style={{ marginBottom: "2rem" }}>
          <p>
            <Emoji symbol="👉" /> Explore the most popular stocks to watch with
            Chekr at the moment.
            {this.state.totalStocks &&
              this.state.totalUsers &&
              ` Currently there are ${this.state.totalUsers} users watching ${
                this.state.totalStocks
              } stocks.`}
          </p>
        </div>

        {loading && <Loading />}

        {!!users && (
          <ResponsiveTable
            columns={columns}
            data={listOfPopularStocks.map(this.mapStocksToGrid)}
            tableBreakpoints={["xs", "sm"]}
            listBreakpoints={["md", "lg", "xl"]}
            noContentText=" "
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(HomePage);
