import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ExploreIcon from "@material-ui/icons/Explore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import Emoji from "./Emoji";
import * as routes from "../constants/routes";

const LandingPage = () => (
  <>
    <div
      className="landingProblems"
      style={{
        fontSize: "x-large",
        textAlign: "center",
        margin: "1rem"
      }}
    >
      <p>Waste time and energy on the stock market?</p>
      <p>Can't stick to your strategy?</p>
      <p>
        Use{" "}
        <strong>
          <Emoji symbol="🤖" label="A robot" />
          Chekr
        </strong>{" "}
        instead!
      </p>
    </div>

    <div
      style={{
        margin: "auto",
        marginTop: "1rem",
        marginBottom: "1rem",
        padding: "1rem",
        textAlign: "center"
      }}
    >
      <Button
        color="secondary"
        style={{ margin: "0.6rem " }}
        variant="contained"
        size="large"
        component={Link}
        to={routes.SIGN_UP}
      >
        <AccountCircleIcon style={{ marginRight: "1rem" }} />
        TRACK MY STOCKS
      </Button>
      <Button
        color="primary"
        style={{ margin: "0.6rem " }}
        variant="contained"
        size="large"
        component={Link}
        to={routes.HOME}
      >
        <ExploreIcon style={{ marginRight: "1rem" }} />
        EXPLORE STOCKS
      </Button>
    </div>

    <div style={{ display: "flex", flexWrap: "wrap", textAlign: "center" }}>
      <div className="landingEmoji">
        <Emoji symbol="👉" label="A finger pointing right" />
        <p>
          Choose from <strong>10.000+</strong> stocks, currencies and
          cryptocurrencies
        </p>
      </div>
      <div className="landingEmoji">
        <Emoji symbol="🕴" label="Man in business suite levitating" />
        <p>
          Pick a <strong>strategy</strong> to follow for your stocks
        </p>
      </div>
      <div className="landingEmoji">
        <Emoji symbol="🚨" label="A police car's light" />
        <p>
          Get <strong>notified</strong> when you need to take action
        </p>
      </div>
      <div className="landingEmoji">
        <Emoji symbol="🛀" label="A person taking a bath" />
        <p>
          <strong>Relax</strong> and let Chekr watch your stocks
        </p>
      </div>
    </div>

    <h2 style={{ marginTop: "7rem" }}>
      Why I Built This <Emoji symbol="👇" label="A finger pointing down" />
    </h2>
    <div style={{ lineHeight: "1.5rem" }}>
      <p>
        I found myself wasting too much time watching and worrying about the
        daily noise of the stock markets, which I realised after reading{" "}
        <a
          href="https://en.wikipedia.org/wiki/Fooled_by_Randomness"
          title="Fooled by Randomness"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fooled by Randomness
        </a>{" "}
        by Nassim Taleb (
        <a
          href="http://stevenbagley.net/blog/taleb-signal-to-noise.html"
          title="Signal to Noise"
          target="_blank"
          rel="noopener noreferrer"
        >
          get the short story
        </a>
        ). So I decided to create an app that keeps track of my stocks given the
        strategy I decided to follow, in this case the{" "}
        <a
          href="https://www.investopedia.com/ask/answers/013015/why-200-simple-moving-average-sma-so-common-traders-and-analysts.asp"
          title="200 Simple Moving Average (SMA)"
          target="_blank"
          rel="noopener noreferrer"
        >
          Simple Moving Average 50/200 strategy
        </a>
        . The app checks my stocks daily, calculates the SMA50/200 values and
        notifies me via email if I need to take action, like buying or selling a
        stock.
      </p>
      <br />
      <p>
        I have now added support for users, so create and account and keep track
        of your own stocks <Emoji symbol="🤗" label="A person hugging" />
      </p>
      <br />
      <p>
        <Link to={routes.SIGN_UP}>Sign up now</Link> and watch your own stocks
        for free <Emoji symbol="🚀" label="A rocket" />
      </p>
      <br />
      <p>Best regards,</p>
      <p>
        <a href="https://www.lavrell.se" title="Niklas">
          Niklas
        </a>
      </p>
    </div>
  </>
);

export default LandingPage;
