import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { MixpanelConsumer } from "react-mixpanel";

import { SignUpLink } from "./SignUp";
import { PasswordForgetLink } from "./PasswordForget";
import { auth } from "../firebase";
import * as routes from "../constants/routes";

const SignInPage = ({ history }) => (
  <div>
    <h2>Sign In</h2>
    <MixpanelConsumer>
      {mixpanel => <SignInForm mixpanel={mixpanel} history={history} />}
    </MixpanelConsumer>
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

const textFieldCSS = {
  width: 250,
  marginRight: 25
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        this.props.mixpanel.identify(authUser.user.uid);
        this.props.mixpanel.track("Signed In");
        this.setState({ ...INITIAL_STATE });
        history.push(routes.STOCKS);
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{ display: "block", marginBottom: 25 }}>
          <TextField
            id="email"
            label="Email"
            required
            autoFocus
            value={email}
            onChange={event =>
              this.setState(
                byPropKey("email", event.target.value.toLowerCase())
              )
            }
            margin="normal"
            style={textFieldCSS}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            required
            value={password}
            onChange={event =>
              this.setState(byPropKey("password", event.target.value))
            }
            margin="normal"
            autoComplete="current-password"
            style={textFieldCSS}
          />
        </div>
        <Button
          disabled={isInvalid}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Sign In
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default withRouter(SignInPage);

export { SignInForm };
